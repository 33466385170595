import { getNewsCategoryById } from '../../../services/news.categories';
import React, { useState, useEffect, useMemo } from 'react';
import AdminNewNewsCategory from './nuevo';
import { navigate } from 'gatsby';

const EditNewsCategory = ({ params: { id } }: { params: { id: string } }) => {
  const [loading, isLoading] = useState(true);
  const [newsCategory, setNewsCategory] = useState({
    id: '',
    name: '',
  });

  useEffect(() => {
    isLoading(true);
    fetchDataById();
    isLoading(false);
  }, []);

  const fetchDataById = async () => {
    const dataToUpdate = await getNewsCategoryById(id);
    if (!dataToUpdate.data) {
      navigate(`/admin/categorias-de-noticias`);
      return;
    }
    setNewsCategory(dataToUpdate.data);
  }

  const newComponent = useMemo(() =>
    <AdminNewNewsCategory
      id={id}
      name={newsCategory?.name}
      loading={loading}
    />, [newsCategory]);

  return newComponent;
}

export default EditNewsCategory;
